<template>
  <div class="wxpay"></div>
</template>

<script>
import { payApi } from "@/api/payApi.js";
export default {
  data() {
    return {
      redirectUrl: "",
    };
  },
  methods: {
    getBaseInfos() {
      const currentUrl = this.redirectUrl;
      const url = encodeURIComponent(currentUrl);
      const urlWithCode =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        payApi.AppID +
        "&redirect_uri=" +
        url +
        "&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect";
      window.location.href = urlWithCode; // 打开这个链接，你的url后面就会跟上code的参数
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.redirectUrl) {
      this.redirectUrl = this.$route.query.redirectUrl;
      this.getBaseInfos();
    }
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.wxpay {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
}
</style>
